body {
  color: #444;
  font-family: arial,sans-serif;
  padding: 0;
  margin: 0;
  max-width: 600px;
  margin: auto;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.categories {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
}

.category {
  display: block;
  margin: 10px 5px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 44px;
    height: 44px;
    background: #F0F0F0;
    padding: 10px;
    margin-bottom: .5em;
  }

  &--active {
    .category__icon {
      background: #fff;
      box-shadow: 0 0 15px #ccc;
    }
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
}

.productDetail {
  transition-duration: .5s;
  background: #fff;
  position: fixed;
  top: 100vh;
  left: 0;
  right: 0;
  bottom: 0;

  &--show {
    transition-duration: .5s;
    top: 0vh;
  }

  &__content {
    background: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 600px;
    margin: auto;

    article {
      position: relative;
    }
  }

  &__header {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  &__section {
    padding: 15px;
  }

  &__image {
    height: 50vh;
    position: relative;
    left: 15%; 
    scale: 1.1;
  }

  &__back {
    border: none;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 15px;
    top: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(138, 149, 158, 0.5);
    border-radius: 12px;
    z-index: 2;
  }

  &__price-and-qty {
    display: flex;
  }

  &__price {
    font-size: 30px;
    flex-grow: 1;
  }

  &__qty {
    button {
      @extend .qty-button;
    }
    button, span {
      border: none;
      width: 30px;
      height: 30px;
    }
  }

  &__value {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    letter-spacing: 0.05em;
    color: #303030;
    margin: 0 15px;
  }

  h2 {
    font-size: 24px;
  }

  &__description {
    flex-grow: 1;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
    max-height: 30vh;
    overflow: auto; }

  &__add-to-cart {
    border: none;
    padding: .7em 0;
    width: 100%;
    color: #fff;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    background: #303030;
    box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
    border-radius: 8px;
  }
}

.product {
  flex: 1 1 40%;
  margin-bottom: 1.5em;
  padding: 0 15px;
  max-width: 40%;
  h3 {
    margin: 0;
    color: #606060;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
    padding: 0 10px;
  }
  span.price {
    color: #303030;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 600;
  }
  img {
    width: 100%;
  }
}

.Cart {
  position: relative;
  &__Items {
    transition-duration: .5s;
    align-items: center;
    font-size: 9px;
    color: #fff;
    background: red;
    justify-content: center;
    padding: 3px 5px;
    border-radius: 100px;
    border: solid 1px #fff;
    position: absolute;
    left: 15px;
    top: -5px;

    &--blank {
      transition-duration: .5s;
      position: absolute;
      top: -1000px;
    }
  }
}
      
.CartSection {
  transition-duration: .5s;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.CartSection--hide {
    transition-duration: .5s;
    position: fixed;
    top: 150vh;
    left: 0;
    right: 0;
    bottom: 150vh;
  }
  &__content {
    max-width: 600px;
    margin: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    &__header {
      position: relative;
      padding: 20px 15px;

      h3 {
        margin: 0;
        text-align: center;
        width: 100%;
        font-size: 1.5em;
        line-height: 23px;
      }
      button {
        position: absolute;
        left: 0;
      }
      svg {
        position: absolute;
      }
    }
    &__items {
      flex-grow: 1;
      overflow: auto;
      padding: 0 15px;
      ul {
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        width: 100%;
        padding: 10px 0;
        border-bottom: solid 1px #F0F0F0;
      }

      &__image {
        width: 150px;
        height: 150px;
        margin-right: 15px;
        img {width: 100%}
      }

      &__description {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        &__title {
          display: flex;
          flex-grow: 1;
          padding-top: 15px;
        }


        h5 {
          color: #606060;
          font-size: 14px;
          font-weight: normal;
          flex-grow: 1;
          margin: 0;
          font-size: 1.1em;
        }

        &__remove {
        }
      }

      &__detail {
        width: 100%;
        display: flex;
        padding: 15px 0;
        align-items: center;
        justify-content: center;

        .CartSection {
          &__qty {
            flex-grow: 1;
          }

          &__value {
            font-weight: bold;
            font-size: 1em;
            margin: 0 15px;
          }

          &__price {
            font-weight: bold;
            font-size: 1em;
          }
        }
      }
    }

    &__footer {
      margin: 1em 15px;
      display: flex;
      flex-direction: column;

      td{
        padding: 2.5px 0;
        font-size: 1.2em;

        font-weight: bold;
        &:nth-child(1) {
          color: #808080;
        }

        &:nth-child(2) {
          color: #303030;
          text-align: right;
        }
      }
    }
  }

  &__checkout {
    color: #fff;
    text-decoration: none;
    font-style: normal;
    font-size: 20px;
    text-align: center;
    background: #303030;
    box-shadow: 0px 10px 20px rgba(48, 48, 48, 0.25);
    border-radius: 8px;
    padding: 0.7em 0;
    border-radius: 8px;
    margin-top: .5em;
  }
}

.qty-button {
  line-height: 30px;
  text-align: center;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #E0E0E0;
  opacity: .4;
  border-radius: 10px;
}
      
.Loading {
  font-size: inherit;
  margin: auto;
  text-align: center;
  &-Dot {
    display: inline;
    margin-left: 0.2em;
    position: relative;
    opacity: 0;
    animation: showHideDot 2.5s ease-in-out infinite;
    &.one { animation-delay: 0.2s; }
    &.two { animation-delay: 0.4s; }
    &.three { animation-delay: 0.6s; }
  }
}

@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}
